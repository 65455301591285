import React from 'react';
import moment from 'moment';
import { IMAGE_URL } from '../../../constants';
import pdf from '../../../imgs/pdf.svg';

const Nugget = ({value}) => {
  const {title, updated_at, attachment_url, document_file_name} = value || {};
  return (
    <a
      download={document_file_name}
      target="_blank"
      href={`${IMAGE_URL}${attachment_url}`}
      className="list-item"
    >
      <div className="item-ava">
        <div className="user-avatar avatar" style={{width: '50%', height: '50%', padding: '4px'}}>
          <div className="user-avatar">
            <img src={pdf} alt="pdf_icon" />
          </div>
        </div>
      </div>

      <div className="item-profile">
        <div className="item-profile--user">
          <div
            style={{color: '#1177ee', fontWeight: 'bold', fontSize: '16px'}}
          >
            {title}
          </div>
        </div>
        <div className="item-activity">
          <div className="item-activity--text">
            <span style={{color: '#2e3c4c', fontWeight: 'normal', fontSize: '14px'}}>{moment(updated_at).format('DD/MM/YYYY')}</span>
          </div>
        </div>
      </div>
    </a>
  );
}

export default Nugget;
