import { connect } from 'react-redux';
import { Payments } from '../../components';
import { bindActionCreators } from 'redux';
import { paymentActions } from '../../actions';

function mapStateToProps(state) {
  return {
    user: state.authState.user,
    subscriptions: state.paymentState.subscriptions,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startFreeTrial: bindActionCreators(paymentActions.startFreeTrial, dispatch),
    createPayment: bindActionCreators(paymentActions.createPayment, dispatch),
    getSubscriptions: bindActionCreators(paymentActions.getSubscriptions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payments);