import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES, COUNTRIES, USER_TYPE, GP_TYPE } from '../../../constants';
import { Input, AuthenticationButton, Select } from '../../';

const SignUpForm = ({
  onSubmit,
  errors,
  handleFirstName,
  first_name,
  handleLastName,
  last_name,
  handleEmail,
  email,
  // handleEmailConfirmation,
  // email_confirmation,
  handlePassword,
  password,
  onShowPassword,
  showPassword,
  isChecked,
  onCkeckbox,
  handleGMC,
  gmc,
  // practice_name,
  // practice_postcode,
  // handlePostcode,
  // handlePracticeName,
  handleSelect,
  // isGP,
  // isLocum,
  isPracticeNurse,
  isPharmacist,
  handleNMC,
  nmc_pin,
  agreeError,
  handleGPHC,
  gphc_registration,
  loading,
  isPhysicianAssociate,
}) => (
  <form onSubmit={ onSubmit }>
    <Input
      type='text'
      placeholder='First Name'
      styleClass={ errors && errors.first_name ? 'error' : '' }
      error={ errors && errors.first_name && errors.first_name }
      onInputChange={ handleFirstName }
      value={ first_name }
      isHalf
      maxLength={ 20 }
    />
    <Input
      type='text'
      placeholder='Last Name'
      styleClass={ errors && errors.last_name ? 'error' : '' }
      error={ errors && errors.last_name && errors.last_name }
      onInputChange={ handleLastName }
      value={ last_name }
      isHalf
      maxLength={ 20 }
    />
    <Select
      name='user_type'
      options={ USER_TYPE }
      handleSelect={ handleSelect }
      styleClass={ errors && errors.user_type ? 'error' : '' }
      error={ errors && errors.user_type && errors.user_type }
    />
    {/* { isGP &&
      <Select
        name='gp_type'
        options={ GP_TYPE }
        handleSelect={ handleSelect }
        styleClass={ errors && errors.gp_type ? 'error' : '' }
        error={ errors && errors.gp_type && errors.gp_type }
      />
    } */}
    { isPracticeNurse ?
      <Input
        type='text'
        placeholder='NMC Pin'
        styleClass={ errors && errors.nmc_pin ? 'error' : '' }
        error={ errors && errors.nmc_pin && errors.nmc_pin }
        onInputChange={ handleNMC }
        value={ nmc_pin }
      /> : (isPharmacist ?
        <Input
          type='text'
          placeholder='GPhC Registration #'
          styleClass={ errors && errors.gphc_registration ? 'error' : '' }
          error={ errors && errors.gmc && errors.gphc_registration }
          onInputChange={ handleGPHC }
          value={ gphc_registration }
        /> : (!isPhysicianAssociate ?
          <Input
            type='text'
            placeholder='GMC #'
            styleClass={ errors && errors.gmc ? 'error' : '' }
            error={ errors && errors.gmc && errors.gmc }
            onInputChange={ handleGMC }
            value={ gmc }
          /> : null
        )
      )
    }
    <Input
      type='email'
      placeholder='Email'
      styleClass={ errors && errors.email ? 'error' : '' }
      error={ errors && errors.email && errors.email }
      onInputChange={ handleEmail }
      value={ email }
    />
    {/* <Input
      type='email'
      placeholder='Repeat Email'
      styleClass={ errors && errors.email_confirmation ? 'error' : '' }
      error={ errors && errors.email_confirmation && errors.email_confirmation }
      onInputChange={ handleEmailConfirmation }
      value={ email_confirmation }
    /> */}
    <Input
      type='password'
      placeholder='Password'
      styleClass={ `password ${ errors && errors.password ? 'error' : '' }` }
      error={ errors && errors.password && errors.password }
      onInputChange={ handlePassword }
      value={ password }
      onShowPassword={ onShowPassword }
      showPassword={ showPassword }
    />
    <Select
      name='country'
      options={ COUNTRIES }
      handleSelect={ handleSelect }
      styleClass={ errors && errors.country ? 'error' : '' }
      error={ errors && errors.country && errors.country }
    />
    {/* { (isGP || isPracticeNurse) && !isLocum &&
      <Input
        type='text'
        placeholder='Practice name'
        styleClass={ errors && errors.practice_name ? 'error' : '' }
        error={ errors && errors.practice_name && errors.practice_name }
        onInputChange={ handlePracticeName }
        value={ practice_name }
        maxLength={ 50 }
      />
    }
    { (isGP || isPracticeNurse) && !isLocum &&
      <Input
        type='text'
        placeholder='Practice postcode'
        styleClass={ errors && errors.practice_postcode ? 'error' : '' }
        error={ errors && errors.practice_name && errors.practice_postcode }
        onInputChange={ handlePostcode }
        value={ practice_postcode }
        maxLength={ 50 }
      />
    } */}
    <div className="notification-item">
      <div className="checkbox-block">
        <input
          className="styled-checkbox"
          id="styled-checkbox-1"
          type="checkbox"
          value="value1"
          name="email_notifications"
          checked={ isChecked }
          onChange={ onCkeckbox }
        />
        <label htmlFor="styled-checkbox-1"></label>
        <p className="checkbox-text">I agree to the <Link to={ ROUTES.TERMS_AND_CONDITIONS } className="text">Terms of Service </Link>
        and <Link to={ ROUTES.PRIVACY_POLICY } className="text">Privacy Policy</Link>.</p>
      </div>
      { agreeError &&
        <span className='main-auth-error' style={ agreeError ? { marginLeft: '10%' } : {} }>{ agreeError }</span>
      }
    </div>
    <AuthenticationButton title='Sign Up' loading={ loading } />
  </form>
);

export default SignUpForm;