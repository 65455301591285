import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import LogoCompact from '../../../imgs/GPC_vector.svg';

const Footer = ({ showFooter }) => showFooter ? (
  <footer>
    <div className="container">
      <div className="footer-container">
        <div className="footer--cred">
          <div className="gpc-logo">
            <img src={ LogoCompact } alt="GPConsult"/>
          </div>
          <div className="disclaimer">
            <Link to={ ROUTES.ABOUT_US } className="text">About Us</Link>
            <Link to={ ROUTES.TERMS_AND_CONDITIONS } className="text">Terms of Service</Link>
            <Link to={ ROUTES.PRIVACY_POLICY } className="text">Privacy Policy</Link>
            <Link to={ ROUTES.COOKIES_POLICY } className="text">Cookies Policy</Link>
          </div>
          <div className="gpc-cred">
            <span>© 2022 Prescription Revision Ltd</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
) : null;

export default Footer;