import React from 'react';
import { AuthHeader } from '../../';
import Paul_Ryan from '../../../imgs/Paul_Ryan.jpg';
import Rima_Aboud from '../../../imgs/Rima.jpg';

const AboutUs = () => (
  <main>
    <AuthHeader
      title='About Us'
      description=''
    />
    <div className='container text-container'>
      <p>GPConsult.co.uk was founded by Dr. Rima Aboud and Dr. Paul Ryan, both practicing GPs.</p>
      <p>Rima is a GP who qualified from the St. Mary’s London VTS in 2015. Prior to becoming a GP, Rima worked as an engineer which is where her interest in utilising technology for learning stemmed from.</p>
      <p>Paul is the Therapeutics Lead for the Irish College of General Practitioners.  He is dual trained as a pharmacist and GP and has a passion for sharing knowledge.</p>
      <p>Rima and Paul recognised the need to launch GPConsult in the UK to support GPs and GP Registrars in their day-to-day consultations. They launched the website in July 2020.</p>
      <p>GPConsult lists consultation templates on a range of typical GP consultations and include history and exam findings and treatment suggestions as per recognised prescribing guidelines. They are continually being reviewed and updated by GPs working in General Practice and also incorporate feedback by GPs via the feedback section underneath each template. Both Rima and Paul are extremely grateful to all the GPs whose valuable contributions have made this website possible.</p>
      <div className='about_us_drs'>
        <div className='about_us_dr'>
          <p><img src={ Paul_Ryan } alt='Paul_Ryan' /></p>
          <p>Dr. Paul Ryan</p>
        </div>
        <div className='about_us_dr'>
          <p><img src={ Rima_Aboud } alt='Rima_Aboud' /></p>
          <p>Dr. Rima Aboud</p>
        </div>
      </div>
      {/* <p>If you need to contact us for any reason, please email us at <a href='mailto:support@gpconsult.co.uk'>support@gpconsult.co.uk</a>. We would love to hear from you.</p> */}
    </div>
  </main>
);

export default AboutUs;