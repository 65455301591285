import { LOREAL_TYPES } from "./LorealTypes";

export const LOREAL_ITEMS = {
  [LOREAL_TYPES.ACNE_IN_FEMALE]: {
    title: 'ACNE IN FEMALES',
    description: 'This page has been developed to help you by providing useful, educational resources for your patients. The below resources, leaflets, and videos can be shared with patients who suffer from acne, to help them understand the management and treatment of their skin condition.',
    imageUrl: require("../components/screens/Loreal/img/products_bg@3x.webp"),
    showRecommends: true,
    activeItem: 2,
    consultationId: 36,
    items: [{
      id: 1,
      title: 'Product Information: Effaclar',
      imageUrl: require('../components/screens/Loreal/img/png/effacalar@3x.png'),
      details: {
        description: 'The Effaclar Range from La Roche-Posay can be used for the management of mild-to-moderate acne as a monotherapy or add-on therapy to improve compliance and prevent relapses. Learn more about the science behind Effaclar.',
        href: 'https://www.lorealdermatologicalbeauty.com/',
        linkTitle: 'Product Information',
      },
    }, {
      id: 2,
      title: 'Acne Patient Leaflet',
      imageUrl: require('../components/screens/Loreal/img/png/acne_leaflet@3x.png'),
      details: {
        description: 'Patient information leaflet on acne – causes, possible triggers, and treatments with La Roche-Posay’s Effaclar',
        href: 'https://gpconsult.co.uk/pdf/Acne.pdf',
        linkTitle: 'Open Leaflet',
      },
    }, {
      id: 3,
      title: 'Acne Patient Video',
      imageUrl: require('../components/screens/Loreal/img/svg/video.svg'),
      details: {
        description: 'Dr Hiva Fassihi, Consultant Dermatologist, discusses the symptoms and triggers for acne, tips and treatments to manage the condition, and how it can affect your mental health.',
        href: 'https://gpconsult.co.uk/video/AcneCaptioned.mp4',
        linkTitle: 'Watch Video',
      },
    }],
  },
  [LOREAL_TYPES.ACNE_IN_MALE]: {
    title: 'ACNE IN MALES',
    description: 'This page has been developed to help you by providing useful, educational resources for your patients. The below resources, leaflets, and videos can be shared with patients who suffer from acne, to help them understand the management and treatment of their skin condition.',
    imageUrl: require("../components/screens/Loreal/img/products_bg@3x.webp"),
    showRecommends: true,
    activeItem: 2,
    consultationId: 234,
    items: [{
      id: 1,
      title: 'Product Information: Effaclar',
      imageUrl: require('../components/screens/Loreal/img/png/effacalar@3x.png'),
      details: {
        description: 'The Effaclar Range from La Roche-Posay can be used for the management of mild-to-moderate acne as a monotherapy or add-on therapy to improve compliance and prevent relapses. Learn more about the science behind Effaclar.',
        href: 'https://www.lorealdermatologicalbeauty.com/',
        linkTitle: 'Product Information',
      },
    }, {
      id: 2,
      title: 'Acne Patient Leaflet',
      imageUrl: require('../components/screens/Loreal/img/png/acne_leaflet@3x.png'),
      details: {
        description: 'Patient information leaflet on acne – causes, possible triggers, and treatments with La Roche-Posay’s Effaclar',
        href: 'https://gpconsult.co.uk/pdf/Acne.pdf',
        linkTitle: 'Open Leaflet',
      },
    }, {
      id: 3,
      title: 'Acne Patient Video',
      imageUrl: require('../components/screens/Loreal/img/svg/video.svg'),
      details: {
        description: 'Dr Hiva Fassihi, Consultant Dermatologist, discusses the symptoms and triggers for acne, tips and treatments to manage the condition, and how it can affect your mental health.',
        href: 'https://gpconsult.co.uk/video/AcneCaptioned.mp4',
        linkTitle: 'Watch Video',
      },
    }],
  },
  [LOREAL_TYPES.ACTINIC_KERATOSIS]: {
    title: 'ACTINIC KERATOSIS',
    description: 'This page has been developed to help you by providing useful, educational resources for your patients. The below resources, leaflets, and videos can be shared with patients who suffer from actinic keratosis, to help them understand the management and treatment of their skin condition.',
    imageUrl: require("../components/screens/Loreal/img/products_bg@3x.webp"),
    activeItem: 1,
    consultationId: 40,
    items: [{
      id: 1,
      title: 'Product information: Anthelios',
      imageUrl: require('../components/screens/Loreal/img/png/anthelios_information@3x.png'),
      details: {
        description: 'The Anthelios range provides high, broad-spectrum protection and is specifically formulated for sensitive skin and tailored to your skin type.',
        href: 'https://www.lorealdermatologicalbeauty.com/dispatch?returnUrl=/',
        linkTitle: 'Product Information',
      },
    }, {
      id: 2,
      title: 'Sun Protection Patient Leaflet',
      imageUrl: require('../components/screens/Loreal/img/png/sun_protection@3x.png'),
      details: {
        description: 'Patient information leaflet on sun protection – how the sun affects the skin, how to protect the skin on a daily basis and which products to consider for maximum protection.',
        href: 'https://gpconsult.co.uk/pdf/Photoprotection.pdf',
        linkTitle: 'Open Leaflet',
      },
    }, {
      id: 3,
      title: 'Dr Eleanor Higgins Video: How does sun exposure affect acne?',
      imageUrl: require('../components/screens/Loreal/img/svg/video.svg'),
      details: {
        description: 'Dr Eleanor Higgins, Consultant Dermatologist, discusses the effects of sunlight on acne, common myths, and advice to protect acne-prone skin.',
        href: 'https://gpconsult.co.uk/video/DrEleanorHiggins.mp4',
        linkTitle: 'Watch Video',
      },
    }, {
      id: 4,
      title: 'Mole Checking Patient Leaflet',
      imageUrl: require('../components/screens/Loreal/img/png/mole_checking@3x.png'),
      details: {
        description: 'Patient information leaflet on melanoma risk and advice on how to self-examine skin.',
        href: 'https://gpconsult.co.uk/pdf/MoleCheckingLeaflet.pdf',
        linkTitle: 'Open Leaflet',
      },
    }],
  },
  [LOREAL_TYPES.ATOPIC_ECZEMA]: {
    title: 'ATOPIC ECZEMA',
    description: 'This page has been developed to help you by providing useful, educational resources for your patients. The below resources, leaflets, and videos can be shared with patients who suffer from atopic eczema or dry skin, to help them understand the management and treatment of their skin condition.',
    imageUrl: require("../components/screens/Loreal/img/products_bg@3x.webp"),
    activeItem: 0,
    consultationId: 33,
    items: [{
      id: 1,
      title: 'Cost Effectiveness of Emollients in the Prevention of Relapses in Atopic Dermatitis',
      imageUrl: require('../components/screens/Loreal/img/png/booklet.png'),
      details: {
        description: 'Emollients are used in the therapeutic management of dry skin flare ups and can have a significant positive impact on patient’s quality of life. A cost-effectiveness study was conducted using three- state Markov Model over six years to show Lipikar Baume AP+ as the most cost-effective therapy vs. other emollients on the UK market.',
        href: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7762264/',
        linkTitle: 'Read Study',
      },
    }, {
      id: 2,
      title: 'Eczema Patient Leaflet',
      imageUrl: require('../components/screens/Loreal/img/png/eczema_leaflet@3x.png'),
      details: {
        description: 'Patient information leaflet on eczema – the causes, possible triggers, and treatment with La Roche-Posay’s Lipikar',
        href: 'https://gpconsult.co.uk/pdf/Eczema.pdf',
        linkTitle: 'Open Leaflet',
      },
    }, {
      id: 3,
      title: 'Eczema Patient Video',
      imageUrl: require('../components/screens/Loreal/img/video@3x.webp'),
      details: {
        description: 'Professor Nicola Ralph, Consultant Dermatologist, discusses what eczema means, the vicious cycle of itching and shares tips on managing the condition.',
        href: 'https://gpconsult.co.uk/video/EczemaCaptioned.mp4',
        linkTitle: 'Watch Video',
      },
    }, {
      id: 4,
      title: 'Production Information: Lipikar',
      imageUrl: require('../components/screens/Loreal/img/png/lipikar.png'),
      details: {
        description: 'The Lipikar range from La Roche-Posay can be used as monotherapy for the management of dry skin and add-on therapy in mild to moderate eczema (based on the SCORAD) to improve symptoms and reduce flare ups. Learn more about the science behind Lipikar.',
        href: 'https://www.lorealdermatologicalbeauty.com/',
        linkTitle: 'Product Information',
      },
    }],
  },
  [LOREAL_TYPES.CONTACT_DERMATITIS]: {
    title: 'CONTACT DERMATITIS',
    description: 'This page has been developed to help you by providing useful, educational resources for your patients. The below resources, leaflets, and videos can be shared with patients who suffer from contact dermatitis, to help them understand the management and treatment of their skin condition.',
    imageUrl: require("../components/screens/Loreal/img/products_bg@3x.webp"),
    activeItem: 2,
    consultationId: 34,
    items: [{
      id: 1,
      title: 'Science behind CeraVe',
      imageUrl: require('../components/screens/Loreal/img/png/cerave@3x.png'),
      details: {
        description: 'Learn more about the science behind CeraVe ceramides and Multivesicular technology in skin prolonged hydration.',
        href: 'https://www.lorealdermatologicalbeauty.com/',
        linkTitle: 'Product Information',
      },
    }, {
      id: 2,
      title: 'Daily Skincare Patient Leaflet',
      imageUrl: require('../components/screens/Loreal/img/png/skincare_leaflet@3x.png'),
      details: {
        description: 'In this leaflet, patients will learn how to combat a daily routine which involves frequent handwashing and mask wearing with CeraVe skincare products to protect and restore skin.',
        href: 'https://gpconsult.co.uk/pdf/CeraVePatientInformation.pdf',
        linkTitle: 'Open Leaflet',
      },
    }, {
      id: 3,
      title: 'Ceramides and MVE technology video',
      imageUrl: require('../components/screens/Loreal/img/png/mve_video@3x.png'),
      details: {
        description: 'In 2005, a dedicated panel of certified Dermatologists leveraged this overwhelming scientific data to develop a new technology containing skin identical ceramides 1, 3, and 6-II known to be deficient in several skin diseases, fatty acids, and other lipids with a revolutionary delivery system known as MultiVesicular Emulsion Technology, MVE. The technology made the formulas not only efficacious in restoring skin barrier function but it also was able to sustain long-term moisturisation with just one use.',
        href: 'https://gpconsult.co.uk/video/CeraVeBrandVideoMVETechnology.mp4',
        linkTitle: 'Watch Video',
      },
    }, {
      id: 4,
      title: 'RESTORE study Phase II',
      imageUrl: require('../components/screens/Loreal/img/png/booklet.png'),
      details: {
        description: 'Enhancement of stratum corneum lipid structure improves skin barrier function and protects against irritation in adults with dry, eczema-prone skin.',
        href: 'https://doi.org/10.1111/bjd.20955',
        linkTitle: 'Read Study',
      },
    }],
  },
  [LOREAL_TYPES.PSORIASIS]: {
    title: 'PSORIASIS',
    description: 'This page has been developed to help you by providing useful, educational resources for your patients. The below resources, leaflets, and videos can be shared with patients who suffer from contact psoriasis, to help them understand the management and treatment of their skin condition.',
    imageUrl: require("../components/screens/Loreal/img/products_bg@3x.webp"),
    activeItem: 2,
    consultationId: 162,
    items: [{
      id: 1,
      title: 'Science behind CeraVe',
      imageUrl: require('../components/screens/Loreal/img/png/cerave@3x.png'),
      details: {
        description: 'Learn more about the science behind CeraVe ceramides and Multivesicular technology in skin prolonged hydration.',
        href: 'https://www.lorealdermatologicalbeauty.com/',
        linkTitle: 'Product Information',
      },
    }, {
      id: 2,
      title: 'Daily Skincare Patient Leaflet',
      imageUrl: require('../components/screens/Loreal/img/png/acne_leaflet@3x.png'),
      details: {
        description: 'In this leaflet, patients will learn how to combat a daily routine which involves frequent handwashing and mask wearing with CeraVe skincare products to protect and restore skin.',
        href: 'https://gpconsult.co.uk/pdf/CeraVePatientInformation.pdf',
        linkTitle: 'Open Leaflet',
      },
    }, {
      id: 3,
      title: 'Ceramides and MVE technology video',
      imageUrl: require('../components/screens/Loreal/img/png/mve_video@3x.png'),
      details: {
        description: 'In 2005, a dedicated panel of certified Dermatologists leveraged this overwhelming scientific data to develop a new technology containing skin identical ceramides 1, 3, and 6-II known to be deficient in several skin diseases, fatty acids, and other lipids with a revolutionary delivery system known as MultiVesicular Emulsion Technology, MVE. The technology made the formulas not only efficacious in restoring skin barrier function but it also was able to sustain long-term moisturisation with just one use.',
        href: 'https://gpconsult.co.uk/video/CeraVeBrandVideoMVETechnology.mp4',
        linkTitle: 'Watch Video',
      },
    }, {
      id: 4,
      title: 'RESTORE study Phase II',
      imageUrl: require('../components/screens/Loreal/img/png/booklet.png'),
      details: {
        description: 'Enhancement of stratum corneum lipid structure improves skin barrier function and protects against irritation in adults with dry, eczema-prone skin.',
        href: 'https://doi.org/10.1111/bjd.20955',
        linkTitle: 'Read Study',
      },
    }],
  },
  [LOREAL_TYPES.SEBORRHEIC_DERMATITIS]: {
    title: 'SEBORRHEIC DERMATITIS',
    description: 'This page has been developed to help you by providing useful, educational resources for your patients. The below resources, leaflets, and videos can be shared with patients who suffer from contact seborrheic dermatitis, to help them understand the management and treatment of their skin condition.',
    imageUrl: require("../components/screens/Loreal/img/products_bg@3x.webp"),
    activeItem: 3,
    consultationId: 39,
    items: [{
      id: 1,
      title: 'Dandruff Patient Leaflet',
      imageUrl: require('../components/screens/Loreal/img/png/dandruff_leaflet@3x.png'),
      details: {
        description: 'Patient information leaflet on dandruff - possible causes, treatment and management with Vichy’s Dercos DS shampoo.',
        href: 'https://gpconsult.co.uk/pdf/Dandruff.pdf',
        linkTitle: 'Open Leaflet',
      },
    }, {
      id: 2,
      title: 'Dandruff Patient video',
      imageUrl: require('../components/screens/Loreal/img/svg/video.svg'),
      details: {
        description: 'Dr Faraz Ali discusses the different types of dandruff, potential triggers and tips on how to manage and treat dandruff',
        href: 'https://gpconsult.co.uk/video/DandruffCaptioned.mp4',
        linkTitle: 'Watch Video',
      },
    }, {
      id: 3,
      title: 'Product Information: Vichy Dercos',
      imageUrl: require('../components/screens/Loreal/img/png/decros@3x.png'),
      details: {
        description: 'The Dercos DS range from Vichy can be used as a monotherapy for the treatment of dandruff or as an add-on and maintenance therapy for Seborrheic Dermatitis (SD). Dercos DS improves symptoms and prevents relapses for more than 3 weeks. Learn more about the science behind Dercos DS.',
        href: 'https://www.lorealdermatologicalbeauty.com/',
        linkTitle: 'Product Information',
      },
    }, {
      id: 4,
      title: 'Study Comparing Healthy and Dandruff Scalp Microbiome',
      imageUrl: require('../components/screens/Loreal/img/png/booklet.png'),
      details: {
        description: 'Scalp Microbiome imbalance plays a role in dandruff resistance. Commensal bacteria such as S. aureus are as important as fungi in the development of dandruff and SD. A study has been conducted to compare the bacterial balance between healthy and dandruff scalp, a new way to consider new treatments in the management and prevention of dandruff and SD.',
        href: 'https://www.frontiersin.org/articles/10.3389/fcimb.2018.00346/full#:~:text=in%20dandruff%20scalp%20microbiome%20(25.26,corresponding%20to%20uncultured%20Malassezia%20sp.',
        linkTitle: 'Read Study',
      },
    }],
  },
};