import React, { Component } from 'react';
import copy from 'copy-to-clipboard';
import { ConsultationCard, FormContainer, ContactUsForm } from '../../';

class ConsultationDetails extends Component {
  constructor() {
    super();

    this.state = {
      hideDisclaimer: false,
      activeCommentPlaceholder: false,
      attachments: [],
      attachmentsToDisplay: [],
      body: '',
      error: '',
      text: '',
      spendTime: 0,
      statisticSent: false,
    };
  }

  componentDidMount() {
    const { consultationsActions, match: { params }, setStatistic } = this.props;
    const { userAgent } = navigator;
    const { spendTime, statisticSent } = this.state;

    this.timer = setInterval(() => {
      this.setState((state) => ({
        ...state,
        spendTime: state.spendTime + 1,
      }));
    }, 1);

    window.scrollTo(0, 0);
    window.onbeforeunload = () => {
      if (!statisticSent) {
        this.setState({statisticSent: true});
        setStatistic({
          consultation_id: params.id,
          spend_time: spendTime,
          statistic_type: 4,
        });
      }
      clearInterval(this.timer);
    };

    if(userAgent.match('iPhone' || userAgent.match('Android')))
      consultationsActions.getConsultation(params.id, true);
    else
      consultationsActions.getConsultation(params.id, false);

    setStatistic({
      consultation_id: params.id,
      statistic_type: 1,
    });
  }

  componentWillReceiveProps(nextProps) {
    const {sponsor, consultationsActions} = this.props;

    if(nextProps.successMessage) {
      setTimeout(this.props.commentsActions.clearSuccessMessage, 2000);
    }

    if(!sponsor && nextProps.sponsor) {
      consultationsActions.incrementConsultationView(nextProps.consultation.id, nextProps.sponsor.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { suggestionSentSuccess } = this.props;

    if(suggestionSentSuccess !== prevProps.suggestionSentSuccess) {
      this.timeout = setTimeout(this.clearSuccessMessage, 3000);
    }
  }

  componentWillUnmount() {
    const {statisticSent, spendTime} = this.state
    const {consultation, setStatistic} = this.props

    if (!statisticSent) {
      this.setState({statisticSent: true});
      setStatistic({
        consultation_id: consultation.id,
        spend_time: spendTime,
        statistic_type: 4,
      });
    }

    clearInterval(this.timer);
  }

  clearSuccessMessage = () => {
    const {clearSuccessSuggestionMessage} = this.props;
    clearSuccessSuggestionMessage();
  }

  onFocusComment = () => {
    this.setState({ activeCommentPlaceholder: true });
  }

  onHideDisclaimer = () => {
    this.setState({ hideDisclaimer: true });
  }

  onWriteComment = event => {
    const body = event.target.value;

    this.setState({ body });
  }

  handleText = event => {
    const text = event.target.value;

    this.setState({ text });
  }

  addAttachment = event => {
    const attachment = event.target.files;
    const attachmentFile = {
      name: attachment[0].name,
      attachment_content_type: attachment[0].type,
      attachment_file_size: attachment[0].size
    };

    this.setState((prevState) => ({
      ...prevState,
      attachments: [...prevState.attachments, attachment[0]],
      attachmentsToDisplay: [...prevState.attachmentsToDisplay, attachmentFile]
    }));
  }

  removeAttachment = index => {
    const prevAttachments = this.state.attachments;
    prevAttachments.splice(index, 1);
    const prevAttachmentsToDisplay = this.state.attachmentsToDisplay;
    prevAttachmentsToDisplay.splice(index, 1);

    this.setState((prevState) => ({
      ...prevState,
      attachments: prevAttachments,
      attachmentsToDisplay: prevAttachmentsToDisplay
    }));
  }

  addComment = () => {
    const { body, attachments } = this.state;
    const { commentsActions, match: { params } } = this.props;
    const commentData = {
      consultation_id: params.id,
      body,
      attachments
    };

    if(!body || body === '') {
      this.setState({ error: 'Comment can`t be blank.' });
    } else {
      commentsActions.addComment(commentData);
      this.setState({ body: '', attachments: [], activeCommentPlaceholder: false, attachmentsToDisplay: [], error: '' });
    }
  }

  addReply = comment_id => {
    const { commentsActions, match: { params } } = this.props;
    const { body, attachments } = this.state;
    const replyData = {
      comment_id,
      body,
      consultation_id: params.id,
      attachments
    };

    if(!body || body === '') {
      this.setState({ error: 'Reply can`t be blank.' });
    } else {
      commentsActions.addReply(replyData);
      this.setState({ body: '', attachments: [], activeCommentPlaceholder: false, attachmentsToDisplay: [], error: '' });
    }
  }

  onLike = (id, type) => {
    const { commentsActions, match: { params } } = this.props;

    if(type === 'comment') {
      commentsActions.likeForComment(id, params.id);
    } else {
      commentsActions.likeForReply(id, params.id);
    }
  }

  openReportModal = (type, reportable) => {
    this.props.globalActions.toggleReportModal(true, type, reportable);
  }

  hideCommentInput = () => {
    this.setState({ activeCommentPlaceholder: false, error: '' });
  }

  openEditCommentReplyModal = (commentReply, type) => {
    this.props.globalActions.toggleEditCommentReplyModal(true, commentReply, type);
  }

  onDeleteCommentReply = (commentReply, type) => {
    this.props.globalActions.toggleDeleteCommentReplyModal(true, commentReply, type);
  }

  clearError = () => {
    this.setState({ error: '' });
  }

  onSponsorClick = () => {
    const { userAgent } = navigator;
    const { consultationsActions, consultation, sponsor } = this.props;

    // if(userAgent.match('iPhone' || userAgent.match('Android')))
    //   consultationsActions.incrementSponsorClick(consultation.id, true);
    // else
    //   consultationsActions.incrementSponsorClick(consultation.id, false);
    consultationsActions.incrementSponsorClick(consultation.id, sponsor.id);
  }

  showMoreReplies = comment_id => {
    this.props.commentsActions.getComment(comment_id);
  }

  handleCopy = () => {
    const {consultation} = this.props;
    const showKeys = ['history', 'exam', 'impresion', 'plan', 'aditional_info'];
    const keysTitle = {
      'history': 'history',
      'exam': 'exam',
      'impresion': 'impression',
      'plan': 'plan',
      'aditional_info': 'additional info'
    };
    const keys = Object.keys(consultation).filter(key => showKeys.includes(key) && !!consultation[key]);
    const message = keys.map(key => `<p style="text-transform: uppercase;"><b>${keysTitle[key]}</b>${consultation[key]}</p>`).join(' ');
    const result = copy(`<p style="text-transform: uppercase;"><b>${consultation.title}</b></p><br />${message}`, {format: 'text/html'});

    if (result) {
      alert('Successfully copied!');
    }
  }

  onSubmit = e => {
    e.preventDefault();
    const {text} = this.state;
    const {sendSuggestion, consultation} = this.props;
    sendSuggestion(text, consultation.id);
    this.setState({text: ''});
  }

  render() {
    const { hideDisclaimer, text } = this.state;
    const {
      consultation,
      user,
      history,
      suggestionSentSuccess,
      sponsor,
      setStatistic,
    } = this.props;

    return (
      <main>
        { consultation && consultation.id && user &&
          <ConsultationCard
            consultation={ consultation }
            onHideDisclaimer={ this.onHideDisclaimer }
            hideDisclaimer={ hideDisclaimer }
            goBack={ history.goBack }
            user={ user }
            onSponsorClick={ this.onSponsorClick }
            sponsor={ sponsor }
            setStatistic={ setStatistic }
            handleCopy={ this.handleCopy }
          />
        }
        {/* <FormContainer classStyle='signin'>
          <ContactUsForm
            handleText={this.handleText}
            text={text}
            onSubmit={this.onSubmit}
            message={suggestionSentSuccess}
          />
        </FormContainer> */}
        {/* { user && user.role !== 'pharma' &&
          <CommentsSection
            comments={ (consultation && consultation.comments) || [] }
            comments_count={ (consultation && consultation.comments_count) || 0 }
            onFocusComment={ this.onFocusComment }
            activeCommentPlaceholder={ activeCommentPlaceholder }
            addAttachment={ this.addAttachment }
            removeAttachment={ this.removeAttachment }
            attachments={ attachmentsToDisplay }
            body={ body }
            onWriteComment={ this.onWriteComment }
            addComment={ this.addComment }
            user={ user }
            onLike={ this.onLike }
            addReply={ this.addReply }
            openReportModal={ this.openReportModal }
            hideCommentInput={ this.hideCommentInput }
            openEditCommentReplyModal={ this.openEditCommentReplyModal }
            onDeleteCommentReply={ this.onDeleteCommentReply }
            clearError={ this.clearError }
            error={ error }
            successMessage={ successMessage }
            showMoreReplies={ this.showMoreReplies }
            commentWithAllReplies={ comment }
          />
        } */}
      </main>
    );
  }
}

export default ConsultationDetails;
