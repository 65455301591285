import { takeEvery, put, call  } from 'redux-saga/effects';
import { PAYMENT_ACTIONS } from '../constants';
import { authActions, paymentActions } from '../actions';
import { processRequest } from '../services/Api';

function* handleStartFreeTrial() {
  try {
    const { data } = yield call(processRequest, '/subscriptions/free', 'POST');
    if(data.user) {
      yield put(paymentActions.startFreeTrialSuccess());
      yield put(paymentActions.getSubscriptions());
      yield put(authActions.updateUserSuccess(data.user));
    }
  } catch (e) {
    console.log(e);
    yield put(paymentActions.startFreeTrialError(e));
  }
}

function* handleCreatePayment(action) {
  try {
    const { token } = action.payload || {};
    const requestPayload = {token, subscription_type: 2};
    const { data } = yield call(processRequest, '/subscriptions', 'POST', requestPayload);

    if(data.user) {
      yield put(paymentActions.createPaymentSuccess());
      yield put(paymentActions.getSubscriptions());
      yield put(authActions.updateUserSuccess(data.user));
    } else if(data.message === 'Card is verified, waiting for payment status') {
      yield put(paymentActions.createPaymentSuccess());
      yield put(paymentActions.getSubscriptions());
      // yield put(authActions.updateUserSuccess(data.user));
    }
  } catch (e) {
    console.log(e);
    yield put(paymentActions.createPaymentError(e));
  }
}

function* handleGetSubscriptions() {
  try {
    const { data } = yield call(processRequest, '/subscriptions', 'GET');
    if(data.data && data.data.transctions)
      yield put(paymentActions.getSubscriptionsSuccess(data.data.transctions));
  } catch (e) {
    console.log(e);
    yield put(paymentActions.getSubscriptionsError(e));
  }
}

export function* watchPaymentSagas() {
  yield takeEvery(PAYMENT_ACTIONS.START_FREE_TRIAL, handleStartFreeTrial);
  yield takeEvery(PAYMENT_ACTIONS.CREATE_PAYMENT, handleCreatePayment);
  yield takeEvery(PAYMENT_ACTIONS.GET_SUBSCRIPTIONS, handleGetSubscriptions);
};