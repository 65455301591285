import React from 'react';
import { PlainInput } from '../../Inputs/Input';
import { ImageField } from '../../Inputs/ImageField';
import { isEmpty } from 'lodash';
import { IMAGE_URL, USER_TYPES, COUNTRIES, GP_TYPES } from '../../../../constants';
import Select from '../../Inputs/Select';

const EMPTY_SELECT_OPTION = { label: '', value: '' };

const userTypesSelectOptions = Object.keys(USER_TYPES).map(value => ({ value, label: USER_TYPES[value] }));
const countiesSelectOptions = COUNTRIES.map(value => ({ value: value.label, label: value.label }));
// const gpTypesSelectOptions = Object.keys(GP_TYPES).map(value => ({ value, label: GP_TYPES[value] }));

export function InformationForm({ onChange, onSubmit, inputProps, formData, currentUser, errors, message }) {

  const onChangeSelect = (e) => {
    const { name, value } = e.target;
    onChange({ name, value }, e);
  }

  let avatarUrl;
  const { avatar_url, first_name, last_name } = currentUser || {}

  avatar_url && (avatarUrl = `${IMAGE_URL}${currentUser.avatar_url}`);
  const avatarInitials = `${first_name && first_name.charAt(0)} ${last_name && last_name.charAt(0)}`;

  formData.avatar && (avatarUrl = formData.avatar);

  // const isGP = formData.user_type === 'gp';
  // const isLocum = formData.gp_type === 'locum';
  const isPracticeNurse = formData.user_type === 'practice_nurse';
  const isPharmacist = formData.user_type === 'pharmacist';
  const isPhysicianAssociate = formData.user_type === 'physician_associate' || formData.user_type === '4';

  return <>
   <div className="tab tab-information">
     {/* <ImageField
       {...inputProps('avatar')}
       label="YOUR profile photo"
       value={avatarUrl}
       initials={avatarInitials}
     /> */}
     <form name="information" onSubmit={onSubmit}>

       <Select
        {...inputProps('country')}
        className="p-form-item"
        name='country'
        options={[EMPTY_SELECT_OPTION, ...countiesSelectOptions]}
        handleSelect={onChangeSelect}
        styleClass={errors && errors.county ? 'error' : ''}
        label="country"
      />

      <Select
        {...inputProps('user_type')}
        className="p-form-item"
        name='user_type'
        options={[EMPTY_SELECT_OPTION, ...userTypesSelectOptions]}
        handleSelect={onChangeSelect}
        styleClass={errors && errors.user_type ? 'error' : ''}
        label="I am a..."
      />

      {/* {isGP && <>
         <Select
          {...inputProps('gp_type')}
          className="p-form-item"
          name="gp_type"
          options={[EMPTY_SELECT_OPTION, ...gpTypesSelectOptions]}
          handleSelect={onChangeSelect}
          styleClass={errors && errors.gp_type ? 'error' : ''}
          label="GP Type"
        />
      </>} */}

      { isPracticeNurse ?
        <PlainInput
          {...inputProps('nmc_pin')}
          label="NMC Pin"
          type="text"
        /> : (isPharmacist ?
          <PlainInput
            {...inputProps('gphc_registration')}
            label="GPhC Registration #"
            type="text"
          /> : (!isPhysicianAssociate ?
            <PlainInput
              {...inputProps('gmc')}
              label="GMC #"
              type="text"
            /> : null
          )
        )
      }

      {/* { (isGP || isPracticeNurse) && !isLocum &&
        <PlainInput
          {...inputProps('practice_name')}
          label="practice name"
        />
      }
      { (isGP || isPracticeNurse) && !isLocum &&
        <PlainInput
          {...inputProps('practice_postcode')}
          label="Practice Postcode "
        />
      } */}
      { message && <p className='profile-updated-success-message'>{ message }</p> }
      <button className='btn large filled submit'>SAVE</button>
     </form>
   </div>
  </>
};

InformationForm.fields = ['avatar', 'gmc', 'user_type', 'country', 'nmc_pin', 'gphc_registration'];
InformationForm.validate = (formData) => {
  const errors = {};

  if (isEmpty(errors)) {
    return false;
  }

  return errors;
}
