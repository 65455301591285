import React from 'react';
import { AuthHeader } from '../../';

const CookiesPolicy = () => (
  <main>
    <AuthHeader
      title='Cookies Policy'
      description=''
    />
    <div className='container text-container'>
      <p><strong>Information about our use of cookies</strong></p>
      <p>Like many websites, <a href='www.gpconsult.co.uk'>www.gpconsult.co.uk</a> (the “Website”) uses "cookies" to enable us to keep track of your preferences and to track the usage of the Website.</p>
      <p><strong>What are cookies?</strong></p>
      <p>Cookies are small text files that are used to store or retrieve information so that certain functions can perform. Cookies are used by many websites to do a number of things e.g. remember your visits to a website; your preferences on that website; and counting the number of people visiting a website. A further explanation about cookies is available on: <a href='http://www.allaboutcookies.org/'>http://www.allaboutcookies.org/</a> and <a href='http://www.youronlinechoices.eu/'>http://www.youronlinechoices.eu/</a>.</p>
      <p><strong>What types of cookies we use</strong></p>
      <p>We don’t use any of our own cookies on the Website.</p>
      <p><strong>Third-party cookies</strong></p>
      <p>We may also use various third-parties cookies to report usage statistics of the Website, to facilitate online payments, and so on.</p>
      <p><strong>Your choices</strong></p>
      <p>If you’d like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser. Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.</p>
      <p>For the Chrome web browser, please visit this page from Google:<br/> <a href='https://support.google.com/accounts/answer/32050'>https://support.google.com/accounts/answer/32050</a></p>
      <p>For the Internet Explorer web browser, please visit this page from Microsoft:<br/> <a href='http://support.microsoft.com/kb/278835'>http://support.microsoft.com/kb/278835</a></p>
      <p>For the Firefox web browser, please visit this page from Mozilla:<br/> <a href='https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored'>https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a></p>
      <p>For the Safari web browser, please visit this page from Apple:<br/> <a href='https://support.apple.com/en-ie/guide/safari/manage-cookies-and-website-data-sfri11471/mac'>https://support.apple.com/en-ie/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></p>
      <p>For any other web browser, please visit your web browser’s support pages.</p>
      <p>For more information on how to control your cookies go to www.allaboutcookies.org. If you are primarily concerned about third party cookies generated by advertisers, you can turn these off by going to <a href='http://www.youronlinechoices.com/ie/'>http://www.youronlinechoices.com/ie/</a>.</p>
      <p>This Cookie Policy forms part of our overall Privacy Statement.</p>
    </div>
  </main>
);

export default CookiesPolicy;