import React from 'react';
import CategoryCard from './CategoryCard';
import {differenceInDays} from '../../../services';

const getText = user => {
  const days = differenceInDays(user.subscription_end_time);
  return days > 0 ?
    `Your free trial lasts for ${days} more days. After this, if GP Consult is beneficial to you, you can subscribe for one year at a cost of £ 190 per year` :
    'Your free trial has finished. If you click on any topic below, it will bring you to our payment page to subscribe to GP Consult for one year.'
};

const Categories = ({ categories, letters, user }) => (
  <section className="sec-categories-list">
    <div className="container">
      {/* {user && user.subscription_type === 'free' ? (
          <div style={{textAlign: 'center'}}>
            <p style={{ margin: '10px 0' }}>{getText(user)}</p>
          </div>
        ) : null
      } */}
      <div className="categories-title">
        <p>CONSULTATION CATEGORIES</p>
      </div>
      <div className="categories-container">
        { letters && letters.length > 0 && letters.map(letter =>
          <CategoryCard
            key={ letter }
            categories={ categories[letter] }
            letter={ letter }
          />
        )}
      </div>
    </div>
  </section>
);

export default Categories;