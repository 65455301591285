import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BackgroundImageSmall from '../../../imgs/g-hero-small.png';
import BackgroundImage from '../../../imgs/g-hero.png';
import GrowthIcon from '../../../imgs/likes-icon.svg';
import LikesIcon from '../../../imgs/growth-icon.svg';
import GWhatSmall from '../../../imgs/g-what-small.png';
import GWhat from '../../../imgs/g-what.png';
import { ROUTES } from '../../../constants';
import emedica_logo from '../../../imgs/emedica_logo.jpg';
import {
  ColabIcon,
} from '../../';

class Home extends Component {
  componentWillMount() {
    const { globalActions: { toggleFooter } } = this.props;

    toggleFooter(true);
  }

  componentWillUnmount() {
    const { globalActions: { toggleFooter } } = this.props;

    toggleFooter(false);
  }

  onCloseMenu = () => {
    const { globalActions: { toggleMenu } } = this.props;

    toggleMenu(false);
  }

  render() {
    return (
      <main className="main-content">
          <section className='sec-guest-hero'>
            <div className="hero-img limit-width">
              <picture>
                <source media="(max-width: 575px)" srcSet={BackgroundImageSmall}/>
                <source media="(min-width: 576px)" srcSet={BackgroundImage}/>
                <img src={BackgroundImage} alt="doc"/>
              </picture>
              <div className="hero-text">
                <div className="container">
                  <div className="hero-text-container">
                    <div className="text-container">
                      <p>Consultation Templates</p>
                      <p>For Conditions Commonly</p>
                      <p>Seen in General Practice</p>
                    </div>
                    <div className="create-acc-container">
                      {/* <span
                        style={{
                          fontSize: '16px',
                          marginTop: '24px',
                          paddingLeft: '15px'
                        }}
                      >
                        After that, an annual subscription costs £ 190/year
                      </span> */}
                      {/* <Link onClick={ this.onCloseMenu } to={ROUTES.SIGN_UP} className="btn shadow large filled" style={{maxWidth: '270px'}}>START 1 MONTH FREE TRIAL</Link> */}
                      <Link onClick={ this.onCloseMenu } to={ROUTES.SIGN_UP} className="btn large filled shadow">JOIN US - IT'S FREE</Link>
                      <span>Are you a GP or a Practice Nurse?</span>
                    </div>
                  </div>
                </div>
                {/* <div className="navigation-sponsor dashboard-sponsor">
                  <div className='sponsor-title'>
                    <p style={{ margin: '10px 0' }}>{ 'Supported by: ' }</p>
                  </div>
                  <div className='support-logo'>
                    <img src={ medprotec } alt="support logo" style={{ maxWidth: '150px' }} />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="hero-features">
              <a className='emedica-link' href='http://www.emedica.co.uk' target='_blank'>
                In partnership with:
                <img style={{maxWidth: '250px'}} src={emedica_logo} />
              </a>
              <div className="limit-width">
                <div className="container">
                  <div className="features-container">
                    <p className="features-heading">FEATURES</p>
                    <div className="features-wrapper">
                      <div className="feature-card">
                        <div className="card-img">
                          {ColabIcon}
                        </div>
                        <div className="card-text">
                          <p>Consultation Templates</p>
                          <p>Consultation templates updated by a community of GP peers</p>
                        </div>
                      </div>
                      <div className="feature-card">
                        <div className="card-img">
                          <img src={GrowthIcon} alt="img"/>
                        </div>
                        <div className="card-text">
                          <p>Management Plans</p>
                          <p>Management plans for conditions based on best practice suggestions from GPs </p>
                        </div>
                      </div>
                      <div className="feature-card">
                        <div className="card-img">
                          <img src={LikesIcon} alt="img"/>
                        </div>
                        <div className="card-text">
                          <p>Improve Notekeeping Quality</p>
                          <p>Improve the quality of your note keeping</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="sec-guest-what">
            <div className="container">
              <div className="what-container">
                <div className="what--image">
                  <picture>
                    <source media="(max-width: 400px)" srcSet={GWhatSmall}/>
                    <source media="(min-width: 401px)" srcSet={GWhat}/>
                    <img src={GWhat} alt="comp"/>
                  </picture>
                </div>
                <div className="what--text">
                  <p>What is</p>
                  <p>GPConsult?</p>
                  <p>
                    GPConsult.co.uk is an online service offering a range of consultation templates for conditions commonly seen in General Practice.
                    <br/><br/>
                    These templates have been created by a number of GPs working at the coal face of General Practice and can be used to help document the symptoms, signs and management plans of these conditions.
                    <br/><br/>
                    GPConsult.co.uk is only open to GPs and Practice Nurses.
                    <br/><br/>
                    It was founded by Dr Rima Aboud, NHS GP and Dr Paul Ryan, GP based in Cork, Ireland and Therapeutics Lead for the Irish College of General Practitioners and launched in June 2020.
                  </p>
                  <Link onClick={ this.onCloseMenu } to={ROUTES.SIGN_UP} className="btn large filled shadow">JOIN US</Link>
                </div>

              </div>
            </div>
          </section>
    </main>
    )
  }
}

export default Home;